import { graphql, useStaticQuery } from "gatsby"

const useMangas = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiManga {
        nodes {
          id
          slug
          title
          poster {
            sharp: childImageSharp {
              fluid(maxWidth: 240, maxHeight: 350) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return data.allStrapiManga.nodes
}

export default useMangas
