import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { css } from "@emotion/core"

const Manga = ({ manga, preview }) => {
  if (preview) {
    return (
      <div className="box">
        <Link to={`/${manga.slug}`}>
        <div className="title">{manga.title}</div>
        <Image
          fluid={manga.poster.sharp.fluid}
          css={css`
            * {
              margin-top: 0;
            }
          `}

        />
        </Link>
      </div>
    )
  }
}

export default Manga
