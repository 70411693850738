import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Manga from "../components/manga"
import useMangas from "../hooks/use-mangas"

const IndexPage = () => {
  const mangas = useMangas()

  return (
    <Layout>
      <SEO title="Inicio" />
      <div className="mangas">
        {mangas.map(manga  => (
          <Manga id={manga.id} manga={manga} preview />
        ))}
      </div>
    </Layout>
  )
}
export default IndexPage
